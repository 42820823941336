import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { withCommercePlatform } from "@spscommerce/ui-react";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

import { SpsIcon } from "@spscommerce/ds-react";
import { SpsIcon as Icon } from "@spscommerce/ds-shared";
import { NavLink } from "react-router-dom";
import LoginCard from "../../components/LoginCard/LoginCard";
import CreateUserV2 from "./CreateUser/CreateUserV2";
import CreateNewPasswordV2 from "./CreateNewPassword/CreateNewPasswordV2";
import NoUserFoundV2 from "./NoUserFound/NoUserFoundV2";
import ResetPasswordV2 from "./ResetPassword/ResetPasswordV2";

function LoginPage(props) {
    const { commercePlatform } = props;

    useEffect(() => {
        document.body.classList.add("sps-wallpaper-ul");

        // Clean up
        return () => {
            document.body.classList.remove("sps-wallpaper-ul");
        };
    });

    return (
        <LoginCard
            topShelf={() => (
                <NavLink to="/home" className="signup-page__nav-link signup-page__nav-link--icon">
                    <SpsIcon icon={Icon.ARROW_LEFT_CIRCLE} />
                    <span>Back to Dev Center Home</span>
                </NavLink>
            )}
        >
            <Switch>
                <Route path="/login/" exact render={() => <h2>Login</h2>} />
                <Route path="/login/no-user-found" component={NoUserFoundV2} />
                <Route path="/login/reset-password" component={ResetPasswordV2} />
                <Route
                    path="/login/create"
                    render={() => {
                        return <CreateUserV2 commercePlatform={commercePlatform} />;
                    }}
                />
                <Route path="/login/create-new-password" component={CreateNewPasswordV2} />
            </Switch>
        </LoginCard>
    );
}

export default withCommercePlatform(withLDConsumer()(LoginPage));
