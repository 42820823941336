import React, { useEffect, useState } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";

import {
    SpsButton,
    SpsCheckbox,
    SpsSelect,
    SpsForm,
    formGroup,
    formControl,
    useForm,
} from "@spscommerce/ds-react";
import { withCommercePlatform } from "@spscommerce/ui-react";

import LoginCard from "../LoginCard/LoginCard";

const AuthorizeApp = function ({ location, commercePlatform }) {
    const [orgs, setOrgs] = useState();
    const [queryParams, setQueryParams] = useState({});
    const [selectedOrgId, setSelectedOrgId] = useState();
    const [appName, setAppName] = useState();
    const form = useForm(
        formGroup({
            isAuthorize: formControl(false),
        }),
    );

    useEffect(() => {
        document.body.classList.add("sps-wallpaper-ul");
        // state is before the hash
        // key, has_authed_app and client_id is after hash
        const searchParams = Object.assign(
            getQueryParams(window.location.search),
            getQueryParams(location.search),
        );
        setQueryParams(searchParams);
        fetchOrgInfo(searchParams.key);
        return () => {
            document.body.classList.remove("sps-wallpaper-ul");
        };
    }, []);

    async function fetchOrgInfo(key) {
        const appDomain =
            commercePlatform.environment === "test"
                ? "https://api.test.spsapps.net"
                : "https://api.spscommerce.com";
        const url = `${appDomain}/devcenter-auth0/redirect/v1/login-organizations-cache`;
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const body = {
            key,
        };

        try {
            const orgInfo = await axios.post(url, body, config);
            setAppName(orgInfo.data.app_name);
            if (orgInfo.data.org_list.length === 1) {
                setSelectedOrgId(orgInfo.data.org_list[0].org_id);
            }
            setOrgs(orgInfo.data.org_list);
        } catch (err) {
            console.error(err);
        }
    }

    const getQueryParams = (search) => {
        // Get search paramaters from URL if present
        const whitelistedParams = ["state", "client_id", "key", "has_authed_app"];

        if (search) {
            return decodeURIComponent(search.slice(1))
                .split("&")
                .map((p) => p.split("="))
                .filter((arr) => whitelistedParams.includes(arr[0]))
                .reduce((result, arr) => {
                    const [param, value] = arr;
                    result[param] = value;
                    return result;
                }, {});
        } else {
            return {};
        }
    };

    async function authorizeApp() {
        const appDomain =
            commercePlatform.environment === "test"
                ? "https://auth.test.spsapps.net"
                : "https://auth.spscommerce.com";
        window.location.replace(
            `${appDomain}/continue?state=${queryParams.state}&org_id=${selectedOrgId}`,
        );
    }

    function handleOrgChange(event) {
        event.target.value
            ? setSelectedOrgId(orgs.find((org) => org.org_name === event.target.value).org_id)
            : setSelectedOrgId();
    }

    function orgsSearch(search) {
        return orgs.map((s) => s.org_name).filter((s) => new RegExp(search, "i").test(s));
    }

    return (
        <LoginCard bodyClass="authorize-app">
            <div className="authorize-app__body">
                <div className="sps-logo-ul">
                    <img
                        src="https://www.spscommerce.com/wp-content/uploads/2018/07/logo-blue.svg"
                        alt="sps commerce logo"
                    />
                </div>
                <SpsForm formGroup={form}>
                    <h1 className="mb-3">Authorize an app</h1>
                    <div className="mb-3 fs-14">
                        <p className="fs-14">
                            The <b>{appName}</b> app is requesting access to your SPS Commerce
                            account. Please select the organization that will be using this app.
                        </p>
                        {orgs && (
                            <SpsSelect
                                className="mb-3"
                                options={orgsSearch}
                                onChange={handleOrgChange}
                                placeholder="Select an organization"
                            />
                        )}
                    </div>
                    {queryParams.has_authed_app === "true" && orgs ? (
                        <div className="mt-3">
                            <SpsCheckbox
                                name="isAuthorize"
                                formControl={form.get("isAuthorize")}
                                label={`I authorize the use of the ${appName} app`}
                            />
                        </div>
                    ) : null}
                    <div className="mt-3">
                        {orgs && queryParams.has_authed_app === "true" ? (
                            <div className="signup-page__container-submit-btn-ul">
                                <SpsButton
                                    kind="confirm"
                                    data-testid="form-authorize"
                                    disabled={
                                        selectedOrgId === undefined ||
                                        !form.get("isAuthorize").value
                                    }
                                    onClick={authorizeApp}
                                    className="signup-page__container-submit-btn-ul"
                                >
                                    Authorize
                                </SpsButton>
                            </div>
                        ) : (
                            <div className="signup-page__container-submit-btn-ul">
                                <SpsButton
                                    onClick={authorizeApp}
                                    type="submit"
                                    kind="confirm"
                                    data-testid="form-submit"
                                    disabled={selectedOrgId === undefined}
                                    className="signup-page__container-submit-btn-ul"
                                >
                                    Continue
                                </SpsButton>
                            </div>
                        )}
                    </div>
                </SpsForm>
            </div>
        </LoginCard>
    );
};

export default withCommercePlatform(withRouter(AuthorizeApp));
